import React, { useState } from "react";
import { css, jsx } from "@emotion/react";
import { graphql, Link } from "gatsby";
import styled from "@emotion/styled";
import SEO from "../components/seo";
import { colors, Input, Button } from "../style/theme.js";
import { mq } from "../utils/helper";
import Layout from "../components/layout";
import Pagination from "rc-pagination";
import "../style/pagination.css";
import { slice } from "ramda";

const Box = styled.div({
  margin: "1rem auto",
  boxShadow: "1px 2px 15px #e5e5e5",
  backgroundColor: colors.light,
  width: "100%",
  padding: "1rem",
  [mq[0]]: {
    padding: "1rem",
  },
});

const LocaleLink = styled(Link)({
  color: colors.black,
  textDecoration: "none",
  transition: "0.25s",
  ":hover, :active": {
    color: colors.green,
  },
});

function Fahras({ data }) {
  const allBlogs = data.postgres.allBlogs.nodes;
  const [blogsList, setBlogsList] = useState(allBlogs);
  const [currentPage, setCurrentPage] = useState(1);
  const [query, setQuery] = useState("");
  const PER_PAGE = 14;
  return (
    <Layout>
      <SEO title="فهرس المدوّنات" />
      <Box css={{ marginTop: "1.5rem" }}>
        <h4 css={{ textAlign: "center" }}>فهرس المدوّنات العربيّة</h4>
        <form
          onSubmit={async (event) => {
            event.preventDefault();
            const filterd = allBlogs.filter((node) => {
              return (
                node.title.includes(query) ||
                node.creator.includes(query) ||
                node.country.includes(query) ||
                node.tags.includes(query)
              );
            });
            setBlogsList(filterd);
          }}
          css={{
            margin: 0,
            display: "flex",
            justifyContent: "center",
            [mq[1]]: {
              display: "block",
            },
          }}
        >
          <Input
            type="text"
            placeholder="أدخل عنوان مدونة، أو اسم مدوّن\ة أو اسم بلد أو وسمًا للبحث"
            onChange={(event) => setQuery(event.target.value)}
            css={{
              width: "70%",
              [mq[1]]: {
                width: "100%",
              },
            }}
          />
          <Button type="submit" css={{ marginTop: 0 }}>
            ابحث
          </Button>
        </form>
      </Box>

      <div
        css={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gridColumnGap: "1rem",
        }}
      >
        {slice(
          PER_PAGE * (currentPage - 1),
          PER_PAGE * (currentPage - 1) + PER_PAGE,
          blogsList
        ).map((node) => (
          <Box key={node.id}>
            <h5
              css={{
                margin: 0,
                textAlign: "center",
              }}
            >
              <LocaleLink to={`/blog/${node.id}`}> {node.title}</LocaleLink>
            </h5>
          </Box>
        ))}
      </div>

      <Box css={{ marginBottom: "1.5rem" }}>
        <h5 css={{ marginLeft: "1rem" }}>الصفحات:</h5>
        <Pagination
          pageSize={20}
          current={currentPage}
          total={blogsList.length}
          onChange={(current) => setCurrentPage(current)}
        />
      </Box>
    </Layout>
  );
}

export default Fahras;

export const pageQuery = graphql`
  query Fahras {
    postgres {
      allBlogs {
        nodes {
          title
          url
          category
          country
          creator
          feedUrl
          id
          tags
          createdAt
        }
      }
    }
  }
`;
